<template>
  <div class="ConseillerSalariesDashboard" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Espace conseiller - Votre salarié {{ salarie.user.first_name }} {{ salarie.user.last_name}}</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- Déjà connecté -->

            <div v-if="!isAuthenticated">
              <h2 class="text-center">
                Vous n'êtes pas connecté
              </h2>
              <p>
                Vous n'êtes pas connecté à votre compte
              </p>
              <b-button block variant="outline-primary" class="mt-2 btn-lg" @click="goToLogin">
                Se connecter
              </b-button>
            </div>

            <div v-else>

              <!-- ERROR MODAL -->
              <b-modal ref="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
                <div class="text-center">
                  <p>Une erreur s'est produite lors de la communication avec le serveur,
                  veuillez vérifier réessayer</p>
                  <b-button class="mt-3" variant="outline-success" @click="hideGlobalAPILoadingErrorModal">Fermer</b-button>
                </div>
              </b-modal>

              <!-- Validated Salarie -->
              <b-modal ref="validateSalarieModal" hide-footer>
                <template v-slot:modal-title>
                  <strong> Valider le dossier fourni par le salarié </strong>
                </template>
                <p> Vous pouvez valider le dossier final fourni par le salarié et passer à la constitution du BRI. </p>
                <div>
                  <b-button
                    block :variant="validateSalarieButtonStyle" class="mt-2"
                    :disabled="validateSalarieInProcess"
                    @click='validateSalarie'>
                      <b-spinner small label="update EtatCivil Spinner" v-if="validateSalarieInProcess"></b-spinner> {{ validateSalarieButtonText }}
                  </b-button>
                </div>
              </b-modal>

              <b-modal ref="validatedSalarieModal" hide-footer title="Vous avez bien validé le dossier du salarié">
                <div class="text-center">
                  <p>
                    <strong>Vous avez bien validé le profil global du salarié et pouvez passer à la constitution du BRI en
                      vous rendant dans l'onglet "BRI".
                    </strong>
                  </p>
                  <b-button class="mt-3" variant="outline-success" @click="hideValidatedSalarieModal">Fermer</b-button>
                </div>
              </b-modal>

              <b-modal ref="refuseSalarieModal" hide-footer>
                <template v-slot:modal-title>
                  <strong> Refuser le dossier fourni par le salarié </strong>
                </template>
                <div>
                  <b-form>
                    <b-form-group id="refuse-salarie-message-input" label="Raisons du refus" label-for="refuse-salarie-message-input">
                      <b-form-textarea
                        required v-model="refuseSalarieMessage"
                        placeholder="Renseignez les raisons de votre refus (visibles par le salarié)"
                        id="refuse-salarie-message-input"
                        maxlength="299"
                        rows="5"
                        @input="$v.refuseSalarieMessage.$touch()"
                        :state="$v.refuseSalarieMessage.$dirty ? !$v.refuseSalarieMessage.$error : null"/>
                    </b-form-group>
                    <b-button
                      block :variant="refuseSalarieButtonStyle" class="mt-2"
                      :disabled="refuseSalarieInProcess || $v.refuseSalarieMessage.$invalid"
                      @click='refuseSalarie'>
                        <b-spinner small label="update EtatCivil Spinner" v-if="refuseSalarieInProcess"></b-spinner> {{ refuseSalarieButtonText }}
                    </b-button>
                  </b-form>
                </div>
              </b-modal>

              <!-- Refused Etat civil -->
              <b-modal ref="refusedSalarieModal" hide-footer title="Vous avez refusé le dossier du salarié">
                <div class="text-center">
                  <p><strong>Vous avez refusé le dossier fourni par le salarié.</strong></p>
                  <p>Celui-ci sera invité à compléter / rectifié sa déclaration
                  et à réitérer sa demande de validation.</p>
                  <b-button class="mt-3" variant="outline-success" @click="hideRefusedSalarieModal">Fermer</b-button>
                </div>
              </b-modal>


              <b-modal ref="munaModifiedModal" hide-footer title="Vous avez bien modifié le Muna du salarié">
                <div class="text-center">
                  <p>
                    <strong>Vous avez bien modifié le Muna du salarié
                    </strong>
                  </p>
                  <b-button class="mt-3" variant="outline-success" @click="hideMunaModifierModal">Fermer</b-button>
                </div>
              </b-modal>

              <!-- Upload BRI -->
              <b-modal ref="uploadBRIModal" hide-footer>
                <template v-slot:modal-title>
                  <strong> Charger un nouveau BRI</strong>
                </template>
                <div>
                  <b-form>
                    <b-form-group>
                      <span> BRI actuel : <a href="#" @click="getSalarieProtectedBRI(bri.id, bri.file_name)">{{ bri.file_name }}  </a> </span> <br>
                      <em><small>( {{ sizeFormatter(bri.file_size) }} )</small></em>
                        <b-form-file
                          required
                          browse-text='Parcourir'
                          v-model="briDocument"
                          id="bri-file-input"
                          placeholder="Choisissez un fichier"
                          drop-placeholder="Déposez un fichier ici"
                          @input="$v.briDocument.$touch()"
                          :state="$v.briDocument.$dirty ? !$v.briDocument.$error : null"
                        ></b-form-file>
                        <div class="form-error" v-if="!$v.briDocument.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 5 MB.</div>
                    </b-form-group>

                    <b-button
                      block :variant="uploadBRIButtonStyle" class="mt-2"
                      @click='uploadBRI'
                      :disabled="uploadBRIInProcess || $v.briDocument.$invalid  ">
                        <b-spinner small label="Upload Id card Spinner" v-if="uploadBRIInProcess"></b-spinner> {{ uploadBRIButtonText }}
                    </b-button>
                  </b-form>
                </div>
              </b-modal>

              <b-modal ref="briUploadedModal" hide-footer title="Vous avez bien chargé le BRI du salarié">
                <div class="text-center">
                  <p><strong>Vous avez chargé le BRI du salarié, celui-ci peut désormais y accéder depuis son tableau de bord.</strong>
                  </p>
                  <b-button class="mt-3" variant="outline-success" @click="hideBriUploadedModal">Fermer</b-button>
                </div>
              </b-modal>

              <div class="text-center" v-if="salarie.state == '2ONGOING'">
                <strong> Le salarié a fait une demande de validation de son profil. Vous pouvez maintenant valider le dossier du
                  salarié pour passer à la constitution et l'envoi du BRI.
                </strong><br>
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <b-button
                      variant="outline-danger" class="option-button mt-2"
                      @click='showRefuseSalarieModal'>
                        Refuser le dossier du salarié
                    </b-button>
                  </div>
                  <div class="col-12 col-sm-6">
                    <b-button
                      variant="outline-success" class="option-button mt-2"
                      @click='showValidateSalarieModal'>
                        Valider le dossier du salarié
                    </b-button>
                  </div>
                </div>
                <hr>
              </div>

              <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-primary">

                <b-tab active>
                  <template slot="title">
                    <span class="h4"> Dossier du salarié </span>
                  </template>

                  <div class="row">
                    <div class="col-12">
                      <strong style="font-size: 1.3em;"> Statut actuel du dossier du salarié :
                      <span v-if="salarie.state == '1CREATED'" class="text-secondary"> En cours de remplissage par le salarié </span>
                      <span v-if="salarie.state == '2ONGOING'" class="text-primary"> A valider </span>
                      <span v-if="salarie.state == '3REFUSED'" class="text-danger"> Refusé, à reremplir par le salarié </span>
                      <span v-if="salarie.state == '4VALIDATED'" class="text-success"> Validé, BRI en construction </span>
                      <span v-if="salarie.state == '5BRI'" class="text-success"> BRI envoyé</span>
                      <span v-if="salarie.state == '6RDV'" class="text-success"> Rendez-vous pris le salarié</span>
                      </strong>
                    </div>
                  </div>
                  <div v-if="!salarie.etat_civil.has_been_updated" class="row">
                    <div class="col-12">
                      <strong class="text-danger"> Le salarié n'a pas encore renseigné les informations de son dossier  </strong>
                    </div>
                  </div>
                  <div v-if="salarie.state == '3REFUSED'" class="row">
                    <div class="col-12">
                      <strong> Raisons du refus :</strong> <em> "{{ salarie.refused_message}}" </em>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-6 col-12">
                      <b-form-group id="muna-form-group" label="Muna du salarié" label-for="muna-form-input" label-cols-md="4" label-cols-lg="4">
                        <b-form-input
                          id="muna-form-input"
                          type="text" maxlength="11"
                          v-model.trim.lazy="salarieMuna"
                          @input="$v.salarieMuna.$touch()"
                          :state="$v.salarieMuna.$dirty ? !$v.salarieMuna.$error : null"
                          placeholder="XXXXXXXX.XX">
                        </b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6 col-12">
                      <b-button
                        block :variant="saveMunaButtonStyle"
                        @click='saveMuna'
                        :disabled="$v.salarieMuna.$invalid">
                          <b-spinner small label="save muna Spinner" v-if="saveMunaInProcess"></b-spinner> {{ saveMunaButtonText }}
                      </b-button>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 10px;">
                    <div class="col-sm-6">
                      <span> Prénom : </span>
                      <strong> {{ salarie.user.first_name }} </strong>
                    </div>
                    <div class="col-sm-6">
                      <span> Nom : </span>
                      <strong> {{ salarie.user.last_name }} </strong>
                    </div>
                  </div>


                  <div class="row" style="margin-top: 10px;">
                    <div class="col-sm-6">
                      <span> Email : </span>
                      <strong> {{ salarie.user.username }} </strong>
                    </div>
                    <div class="col-sm-6">
                      <span> Numéro de téléphone : </span>
                      <strong> {{ salarie.phone_number }} </strong>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px;">
                    <div class="col-sm-12">
                      <span> Date de naissance : </span>
                      <strong> {{ $date(salarie.birthdate).format('DD/MM/YYYY') }} </strong>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 10px;">
                    <div class="col-sm-6">
                      <span> Catégorie professionnelle : </span>
                      <strong> {{ professionalCategoryRender(salarie.professional_category) }} </strong>
                    </div>
                    <div class="col-sm-6">
                      <span> Employeur actuel : </span>
                      <strong> {{ salarie.employeur }} </strong>
                    </div>
                  </div>

                  <hr>
                  <div class="row" style="margin-top: 10px;">
                    <div class="col-sm-6">
                      <span> Numéro de sécurité sociale : </span>
                      <strong> {{ salarie.etat_civil.social_security }} </strong>
                    </div>
                    <div class="col-sm-4">
                      <span> Nombre d'enfants : </span>
                      <strong> {{ salarie.etat_civil.nb_children }} </strong>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 10px;">
                    <div class="col-12">
                      <span> A effectué son service militaire : </span>
                      <strong> <booleanComponent v-bind:value="salarie.etat_civil.is_service_militaire" /> </strong>
                    </div>
                  </div>
                  <div v-if="salarie.etat_civil.is_service_militaire" class="row" style="margin-top: 10px;">
                    <div class="col-sm-6">
                      <span> Date de début : </span>
                      <strong> {{ $date(salarie.etat_civil.beginning_service_militaire).format("DD/MM/YYYY") }} </strong>
                    </div>
                    <div class="col-sm-4">
                      <span> Date de fin : </span>
                      <strong> {{ $date(salarie.etat_civil.ending_service_militaire).format("DD/MM/YYYY") }} </strong>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 10px;">
                    <div class="col-12">
                      <span> A effectué une activité professionnelle à l'étranger : </span>
                      <strong> <booleanComponent v-bind:value="salarie.etat_civil.is_activity_abroad" /> </strong>
                    </div>
                  </div>
                  <div v-if="salarie.etat_civil.is_activity_abroad" class="row" style="margin-top: 10px;">
                    <div class="col-12">
                      <span> Informations sur l'activité professionnelle à l'étranger : </span>
                      <strong> {{ salarie.etat_civil.information_activity_abroad }} </strong>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 10px;">
                    <div class="col-12">
                      <span> Demande un rachat de trimestre : </span>
                      <strong> <booleanComponent v-bind:value="salarie.etat_civil.is_demande_rachat_trimestres" /> </strong>
                    </div>
                  </div>
                  <div v-if="salarie.etat_civil.is_demande_rachat_trimestres" class="row" style="margin-top: 10px;">
                    <div class="col-12">
                      <span> Informations sur la demande de rachat : </span>
                      <strong> {{ salarie.etat_civil.rachat_trimestre_information }} </strong>
                    </div>
                  </div>

                  <div v-if="!salarie.etat_civil.has_been_updated" class="text-center">
                    <h2 class="text-secondary"> Le salarié n'a pas encore renseigné les informations de son dossier </h2>
                  </div>
                </b-tab>

                <b-tab>
                  <template slot="title">
                    <span class="h4" > Documents du salarié</span>
                  </template>
                  <div class="row">
                    <div class="col-12">
                      <strong style="font-size: 1.3em;"> Statut actuel du dossier du salarié :
                      <span v-if="salarie.state == '1CREATED'" class="text-secondary"> En cours de remplissage par le salarié </span>
                      <span v-if="salarie.state == '2ONGOING'" class="text-primary"> A valider </span>
                      <span v-if="salarie.state == '3REFUSED'" class="text-danger"> Refusé, à reremplir par le salarié </span>
                      <span v-if="salarie.state == '4VALIDATED'" class="text-success"> Validé, BRI en construction </span>
                      <span v-if="salarie.state == '5BRI'" class="text-success"> BRI envoyé</span>
                      <span v-if="salarie.state == '6RDV'" class="text-success"> Rendez-vous pris par le salarié</span>
                      </strong>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-12">
                      <h2 style="font-size: 1.3em; margin-bottom: 0px;"> Document d'identité </h2>
                      <em class="text-secondary">Il s'agit de la carte d'identité recto verso ou du passeport (page avec votre photo) </em>
                    </div>
                  </div>

                  <!--  ID CARD -->
                  <div class="row mt-3 text-center">
                    <div class="col-sm-4 align-self-center" v-if="id_card.file_name">
                      <span>Document actuel </span> <br>
                      <a href="#" @click="getProtectedDocument(id_card.id, id_card.file_name)">Télécharger le document  </a> <br>
                      <em><small> ( {{ sizeFormatter(id_card.file_size) }} )</small> </em>
                    </div>
                    <div class="col-sm-4 align-self-center" v-else>
                      <em>Aucun document d'identité encore chargé </em>
                    </div>

                    <div v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'" class="col-sm-8 align-self-center text-center">
                      <strong class="text-success" style="font-size: 1.2em;"> Dossier du salarié validé </strong>
                    </div>
                    <div v-else-if="!id_card.has_been_updated" class="col-sm-8 align-self-center text-center">
                      <strong class="text-secondary" style="font-size: 1.2em;"> Document pas encore chargé par le salarié. </strong>
                    </div>
                    <div v-else class="col-sm-8 align-self-center text-center">
                      <strong class="text-secondary" style="font-size: 1.2em;"> Document chargé par le salarié. </strong>
                    </div>
                  </div>

                </b-tab>

                <!--    BRI -->
                <b-tab active v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'">
                  <template slot="title">
                    <span class="h4"> BRI du salarié</span>
                  </template>
                  <div class="row mt-3">
                    <div class="col-12">
                      <h2 style="font-size: 1.3em; margin-bottom: 0px;"> Bilan retraite individuel </h2>
                      <em class="text-secondary">Chargez ici le bulletin retraite individuel (BRI) de votre salarié </em>
                    </div>
                  </div>

                  <div class="row mt-3 text-center">
                    <div class="col-sm-4 align-self-center" v-if="bri.file_name">
                      <span> BRI actuel </span> <br>
                      <a href="#" @click="getSalarieProtectedBRI(bri.id, bri.file_name)">{{ bri.file_name }}  </a> <br>
                      <em><small> ( {{ sizeFormatter(bri.file_size) }} )</small> </em>
                    </div>
                    <div class="col-sm-4 align-self-center" v-else>
                      <em>Aucun bri encore chargé </em>
                    </div>


                    <div class="col-sm-8 align-self-center text-center">
                      <b-button
                        block variant="outline-secondary" class="mt-2"
                        @click='showUploadBRIModal'>
                          Charger un nouveau BRI
                      </b-button>
                    </div>

                  </div>
                </b-tab>
              </b-tabs>

            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { APIConseillerConnected } from '@/api/APIConseillerConnected'
import booleanComponent from '@/components/booleanComponent'
import { fileOperations } from '@/mixins/utilMixins'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { file_size_validation, isMuna } from '@/validators/validators'

import formTitleComponent from '@/components/formTitleComponent'

const apiConseillerConnected = new APIConseillerConnected()

export default {
  name: 'ConseillerSalariesDashboard',
  components: {
    booleanComponent,
    formTitleComponent
  },
  mixins: [
    validationMixin,
    fileOperations
  ],
  data: function () {
    return {
      salarie: {
        id: 0,
        user: {
          id: 0,
          first_name: '',
          last_name: '',
          username: ''
        },
        conseiller: {
          id: 0,
          user: {
            id: 0,
            first_name: '',
            last_name: '',
            username: ''
          }
        },
        etat_civil: {
          id: 0,
          social_security: '0000000000000',
          nb_children: 0,
          is_service_militaire: false,
          beginning_service_militaire: '',
          ending_service_militaire: '',
          is_activity_abroad: false,
          information_activity_abroad: '',
          is_demande_rachat_trimestres: '',
          rachat_trimestre_information: '',
          has_been_updated: false
        },
        birthdate: '',
        phone_number: '',
        professionalCategory: '',
        state: "1CREATED",
        refused_message: ''
      },

      refuseSalarieMessage: '',
      refuseSalarieButtonText: "Refuser le dossier du salarié",
      refuseSalarieButtonStyle: "outline-danger",
      refuseSalarieInProcess: false,

      validateSalarieButtonText: "Valider le profil global du salarié et passer à la constitution du BRI",
      validateSalarieButtonStyle: "outline-success",
      validateSalarieInProcess: false,

      // ------ MUNA ----------
      salarieMuna: '',
      saveMunaButtonText: "Enregistrer le Muna",
      saveMunaButtonStyle: "outline-danger",
      saveMunaInProcess: false,

      // ------ ID Card ------
      id_card: '',
      idCardLoading: false,

      // --------- BRI ----------
      bri: '',
      briDocument: [],
      uploadBRILoading: false,

      uploadBRIButtonText: "Charger le BRI",
      uploadBRIButtonStyle: 'outline-success',
      uploadBRIInProcess: false,

    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.state.auth.token
    },
  },
  mounted: function() {
    this.getSalarie()
    this.getSalarieDocument('ID')
    this.getSalarieBRI()
  },
  validations: {
    salarieMuna: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
      validMuna(value) {
        return isMuna(value)
      }
    },
    briDocument: {
      required,
      file_size_validation
    },
    refuseSalarieMessage: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(299)
    },
  },
  methods: {
    getSalarie () {
      apiConseillerConnected.getSalarie(this.token, this.$route.params.id)
      .then((result) => {
        this.salarie = result.data
        this.salarieMuna = result.data.muna
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
    },


    getSalarieDocument (type) {
      apiConseillerConnected.getSalarieDocument(this.token, this.$route.params.id, type)
      .then((result) => {
        if (type == 'ID') {
          this.id_card = result.data
        }
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
    },

    goToLogin () {
      this.$router.push('/login')
    },

    getProtectedDocument (document_id, document_name) {
      apiConseillerConnected.getSalarieProtectedDocument(this.token, document_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', document_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
    },


    validateSalarie () {
      this.validateSalarieButtonText = "Validation en cours"
      this.validateSalarieButtonStyle = "secondary"
      this.validateSalarieInProcess = true
      apiConseillerConnected.validateSalarie(this.token, this.salarie.id)
      .then(() => {
        this.getSalarie()
        this.$refs.validateSalarieModal.hide()
        this.$refs.validatedSalarieModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.validateSalarieButtonText = "Valider le profil global du salarié et passer à la constitution du BRI"
        this.validateSalarieButtonStyle = "outline-success"
        this.validateSalarieInProcess = false
      })
    },

    refuseSalarie () {
      this.refuseSalarieButtonText = "Refus en cours"
      this.refuseSalarieButtonStyle = "secondary"
      this.refuseSalarieInProcess = true

      apiConseillerConnected.refuseSalarie(this.token, this.salarie.id, this.refuseSalarieMessage)
      .then(() => {
        this.getSalarie()
        this.$refs.refuseSalarieModal.hide()
        this.$refs.refusedSalarieModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.$refs.refuseSalarieModal.hide()
        this.refuseSalarieButtonText = "Refuser le dossier du salarié"
        this.refuseSalarieButtonStyle = "outline-danger"
        this.refuseSalarieInProcess = false
      })
    },

    saveMuna () {
      this.saveMunaButtonText = "Enregistrement en cours"
      this.saveMunaButtonStyle = "secondary"
      this.saveMunaInProcess = true
      apiConseillerConnected.saveMuna(this.token, this.salarie.id, this.salarieMuna)
      .then(() => {
        this.getSalarie()
        this.$refs.munaModifiedModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.saveMunaButtonText = "Enregistrer le Muna"
        this.saveMunaButtonStyle = "outline-danger"
        this.saveMunaInProcess = false
      })
    },


    // --------------- BRI -------------
    getSalarieBRI () {
      apiConseillerConnected.getSalarieBRI(this.token, this.$route.params.id)
      .then((result) => {
        this.bri = result.data

      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
    },

    getSalarieProtectedBRI (bri_id, bri_name) {
      apiConseillerConnected.getSalarieProtectedBRI(this.token, bri_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', bri_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
    },

    uploadBRI () {
      this.uploadBRIButtonText = "Envoi en cours"
      this.uploadBRIButtonStyle = "secondary"
      this.uploadBRIInProcess = true
      apiConseillerConnected.uploadSalarieBRI(
        this.token, this.bri.id, this.briDocument)
        .then(() => {
          this.$refs.briUploadedModal.show()
          this.getSalarieBRI()
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.$refs.uploadBRIModal.hide()
          this.uploadBRIButtonText = "Charger le BRI"
          this.uploadBRIButtonStyle = "outline-primary"
          this.uploadBRIInProcess = false
        })
    },

    showUploadBRIModal () {
      this.$refs.uploadBRIModal.show()
    },

    showRefuseSalarieModal () {
      this.$refs.refuseSalarieModal.show()
    },
    showValidateSalarieModal () {
      this.$refs.validateSalarieModal.show()
    },

    hideRefusedSalarieModal () {
      this.$refs.refusedSalarieModal.hide()
    },
    hideValidatedSalarieModal () {
      this.$refs.validatedSalarieModal.hide()
    },

    hideMunaModifierModal () {
      this.$refs.munaModifiedModal.hide()
    },

    hideBriUploadedModal () {
      this.$refs.briUploadedModal.hide()
    },
    hideGlobalAPILoadingErrorModal () {
      this.$refs.globalAPILoadingErrorModal.hide()
    },

    professionalCategoryRender(professionalCategory) {
      switch (professionalCategory) {
        case 'SAL':
          return 'Salarié permanent'
        case 'ART':
          return 'Artiste et technicien du spectacle'
        case 'POL':
          return 'Indemnisé Pôle Emploi'
        case 'AUT':
          return 'Autres'
      }
      return 'Erreur'
    },

  }
}
</script>
